jQuery(document).ready(function(){
            //creates url from search input fields with help from regular expression
            jQuery("#searchform2").on('submit', function(){
                var str = jQuery(this).serialize();
                var newstr = str.replace(/(Bauform|[s+&])/g, "").replace(/=/g, "");
                var decodedUri = decodeURIComponent(newstr);
                location.href = '/search/%22'+decodedUri+'%22';
                return false;
                });

            jQuery("#searchform").on('submit', function(){
                var str = jQuery(this).serialize();
                var newstr = str.replace(/(Bauform|s=|%20)/igm, "");
                var decodedUri = decodeURIComponent(newstr);
                location.href = '/search/%22'+decodedUri+'%22';
                return false;
                });

            // This extracts something like " 22x22x7 "" into
            // 3 different text input fields without the " x "
            // old code to auto fill from searchbox1 // jQuery('#searchbox1').keyup(function (e) {
            jQuery('#first', '#second', '#third').keyup(function (e) {
                    var input=jQuery(this).val();
                    // var array = new Array();
                    // array=input.split('x');
                    jQuery('#first').val(array[0]);
                    jQuery('#first2').html(array[0]);
                    jQuery('#second').val(array[1]);
                    jQuery('#second2').html(array[1]);
                    jQuery('#third').val(array[2]);
                    jQuery('#third2').html(array[2]);
                });


            jQuery('#searchbox1').keyup(function (e) {
                    if (e.keyCode == 13) {
                        jQuery(this).val();
                    }
                });
            // This will store and autofill the fields value on next page
            jQuery(window).unload(saveSettings);
            loadSettings();

            // This will store and autofill the fields value on next page
            function loadSettings() {
                jQuery('#searchbox1').val(localStorage.searchbox1);
                jQuery('#first').val(localStorage.first);
                jQuery('#first2').html(localStorage.first2);
                jQuery('#second').val(localStorage.second);
                jQuery('#second2').html(localStorage.second2);
                jQuery('#third').val(localStorage.third);
                jQuery('#third2').html(localStorage.third2);
            }

            function saveSettings() {
                localStorage.searchbox1 = jQuery('#searchbox1').val();
                localStorage.first = jQuery('#first').val();
                localStorage.first2 = jQuery('#first2').html();
                localStorage.second = jQuery('#second').val();
                localStorage.second2 = jQuery('#second2').html();
                localStorage.third = jQuery('#third').val();
                localStorage.third2 = jQuery('#third2').html();
            }

            jQuery('#first').on('input', function(){
                jQuery('#first2').text(jQuery(this).val());
            });
            jQuery('#second').on('input', function(){
                jQuery('#second2').text(jQuery(this).val());
            });
            jQuery('#third').on('input', function(){
                jQuery('#third2').text(jQuery(this).val());
            });

jQuery('#searchform2 input:radio').on('click', function() {
  jQuery('#searchform2 label:has(input:radio:checked)').addClass('active');
  jQuery('#searchform2 label:has(input:radio:not(:checked))').removeClass('active');
});

jQuery('#click_advance').on('click', function() {
    jQuery('#display_advance').toggle('1000');
    jQuery("i", this).toggleClass("icon-circle-arrow-up icon-circle-arrow-down");
});

jQuery(".reset").on('click', function() {
    jQuery(this).closest('form').find("input[type=text], textarea").val("");
});

  	/*Dichta suffix / single*/
    if(window.location.href.match(/(A%22)|(\+A\+)/im)) {jQuery("#eigenschaften, #unterschiede, #A-2, #A, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(B%22)|(\+B\+)/im)) {jQuery("#eigenschaften, #unterschiede, #B-2, #B, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(C%22)|(\+C\+)/im)) {jQuery("#eigenschaften, #unterschiede, #C-2, #C, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(M%22)|(\+M\+)/im)) {jQuery("#eigenschaften, #unterschiede, #M-2, #M, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}

	// set to display: none add class on load to Div
  	if(window.location.href.match(/(BA)/im)) {jQuery("#eigenschaften, #BA, .advice").addClass( "show" ); jQuery("#A ,#A-2, #unterschiede").removeClass( "show" );} else{}
    if(window.location.href.match(/(BA$)|(BA%22)|(\+BA")|(\+BA)/im)) {jQuery("#BA-2, .advice").addClass( "show" ); jQuery("#AS ,#AS-2").removeClass( "show" );} else{}
  	if(window.location.href.match(/(BA$)|(BAOF)|(BAHD)|(BANIF)|(BADUO)|(BAV)|(BAX6)|(BAFUD)/im)) {jQuery("#BA-2, .advice").addClass( "show" );} else{}
    if(window.location.href.match(/(BASLX7$)|(BASLX7%22)|(\+BASLX7")|(BAX7SL$)|(BAX7SL%22)|(\+BAX7SL")/im)) {jQuery("#BASLX7-2, .advice").addClass( "show" ); jQuery("#BA-2").removeClass( "show" );} else{}
    if(window.location.href.match(/(BAX7%22)|(BAX7%22)/im)) {jQuery("#BASLX7-2, #X7, #X7-2, .advice").removeClass( "show" );} else{}

  	if(window.location.href.match(/(B2)/im)) {jQuery("#eigenschaften, #B2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
  	if(window.location.href.match(/(B2)|(B2OF)|(B2HD)|(B2NIF)|(B2DUO)|(B2V)|(B2X6)|(B2FUD)/im)) {jQuery("#B2-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    if(window.location.href.match(/(B2$)|(B2%22)|(\+B2")|(\+B2)/im)) {jQuery("#B2-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    if(window.location.href.match(/(B2X7$)/im)) {jQuery(" #B2-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}

  	if(window.location.href.match(/(B1)/im)) {jQuery("#eigenschaften, #B1, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    if(window.location.href.match(/(B1$)|(B1%22)|(\+B1")|(\+B1)/im)) {jQuery("#B1-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    if(window.location.href.match(/(B1)|(B1OF)|(B1HD)|(B1NIF)|(B1DUO)|(B1V)|(B1X6)|(B1FUD)/im)) {jQuery("#B1-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    /*if(window.location.href.match(/(B1SL$)|(B1SL%22)|(\+B1SL")/im)) {jQuery("#B1SL-2, .advice").addClass( "show" ); jQuery("#B1-2").removeClass("show");} else{}*/

  	if(window.location.href.match(/(BD)/im)) {jQuery("#eigenschaften, #BD, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    if(window.location.href.match(/(BD$)|(BD%22)|(\+BD")|(\+BD)/im)) {jQuery("#BD-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    if(window.location.href.match(/(BD)|(BDOF)|(BDHD)|(BDNIF)|(BDDUO)|(BDV)|(BDX6)|(BDFUD)/im)) {jQuery("#BD-2, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B, #unterschiede").removeClass("show");} else{}
    /*if(window.location.href.match(/(BDSLX7$)|(BDSLX7%22)|(\+BDSLX7")/im)) {jQuery("#BDSLX7-2, .advice").addClass( "show" );} else{}*/

  	if(window.location.href.match(/(BAB)/im)) {jQuery("#eigenschaften, #BAB, .advice").addClass( "show" ); jQuery("#R, #R-2, #B-2, #B").removeClass("show");} else{}
    if(window.location.href.match(/(BAB)|(BABOF)|(BABHD)|(BABNIF)|(BABDUO)|(BABV)|(BABX6)|(BABX7)|(BABSL)|(BABFUD)/im)) {jQuery("#BAB-2, .advice").addClass( "show" ); jQuery("#BA, #R, #R-2, #B-2, #B").removeClass("show");} else{}
  	if(window.location.href.match(/(BAB$)|(BAB%22)|(\+BAB")|(\+BAB)/im)) {jQuery("#eigenschaften, #BAB-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}

  	if(window.location.href.match(/(OF)|(OF%22)|(OF")/im)) {jQuery("#OF, #OF-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(SL)|(SL%22)|(SL")/im)) {jQuery("#SL, #SL-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(HD)|(HD%22)|(HD")/im)) {jQuery("#HD, #HD-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(NIF)|(NIF)|(NIF%22)|(NIF")/im)) {jQuery("#NIF, #NIF-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(X7$)|(X7%22)|(X7")/im)) {jQuery("#X7, #X7-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(DUO)|(DUO%22)|(DUO")/im)) {jQuery("#DUO, #DUO-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(V)|(V%22)|(V")/im)) {jQuery("#V, #V-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(X6$)|(X6%22)|(X6")/im)) {jQuery("#X6, #X6-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
  	if(window.location.href.match(/(FUD)|(FUD%22)|(FUD)/im)) {jQuery("#FUD, #FUD-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}

/* Busak Suffix */
	if(window.location.href.match(/(TRA)/im)) {jQuery("#eigenschaften, #TRA-2, #TRA, .advice").addClass( "show" ); jQuery("#A ,#A-2, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRA$)|(TRA%22)|(\+TRA")|(\+TRA)/im)) {jQuery("#unterschiede, #TRA-2, .advice").addClass( "show" ); jQuery("#A ,#A-2, #R, #R-2").removeClass("show");} else{}
	if(window.location.href.match(/(TRE)/im)) {jQuery("#eigenschaften, #TRE-2, #TRE, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRE$)|(TRE%22)|(\+TRE")|(\+TRE)/im)) {jQuery("#unterschiede, #TRE-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
	if(window.location.href.match(/(TRC)/im)) {jQuery("#eigenschaften, #TRC-2, #TRC, .advice").addClass( "show" ); jQuery("#C ,#C-2, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRC$)|(TRC%22)|(\+TRC")|(\+TRC)/im)) {jQuery("#unterschiede, #TRC-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
	if(window.location.href.match(/(TRD)/im)) {jQuery("#eigenschaften, #TRD-2, #TRD, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRD$)|(TRD%22)|(\+TRD")|(\+TRD)/im)) {jQuery("#unterschiede, #TRD-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
	if(window.location.href.match(/(TRF)/im)) {jQuery("#eigenschaften, #TRF-2, #TRF, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRF$)|(TRF%22)|(\+TRF")|(\+TRF)/im)) {jQuery("#unterschiede, #TRF-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
	if(window.location.href.match(/(TRU)/im)) {jQuery("#eigenschaften, #TRU-2, #TRU, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRU$)|(TRU%22)|(\+TRU")|(\+TRU)/im)) {jQuery("#unterschiede, #TRU-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
	if(window.location.href.match(/(TRK)/im)) {jQuery("#eigenschaften, #TRK-2, #TRK, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(TRK$)|(TRK%22)|(\+TRK")|(\+TRK)/im)) {jQuery("#unterschiede, #TRK-2, .advice").addClass( "show" ); jQuery("#R, #R-2").removeClass("show");} else{}

/*Dichta Suffix*/
    if(window.location.href.match(/(AS%22)|(\+AS\+)/im)) {jQuery("#eigenschaften, #unterschiede, #AS-2, #AS, .advice").addClass( "show" ); jQuery("#A-2, #A").removeClass("show");} else{}
    if(window.location.href.match(/(BS)/im)) {jQuery("#eigenschaften, #unterschiede, #BS-2, #BS, .advice").addClass( "show" ); jQuery("#B-2, #B").removeClass("show");} else{}
    if(window.location.href.match(/(CS)/im)) {jQuery("#eigenschaften, #unterschiede, #CS-2, #CS, .advice").addClass( "show" ); jQuery("#C-2, #C").removeClass("show");} else{}

/*Dichtomatik Suffix*/
    if(window.location.href.match(/(WA$)|(WA%22)|(\+WA")|(\+WA)/im)) {jQuery("#eigenschaften, #unterschiede, #WA-2, #WA, .advice").addClass( "show" ); jQuery("#A-2, #A, #R-2, #R").removeClass("show");} else{}
    if(window.location.href.match(/(WAS)/im)) {jQuery("#eigenschaften, #unterschiede, #WAS-2, #WAS, .advice").addClass( "show" ); jQuery("#WA-2, #WA, #AS, #AS-2, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WB$)|(WB%22)|(\+WB")|(\+WB)/im)) {jQuery("#eigenschaften, #unterschiede, #WB-2, #WB, .advice").addClass( "show" ); jQuery("#B-2, #B, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WBS)/im)) {jQuery("#eigenschaften, #unterschiede, #WBS-2, #WBS, .advice").addClass( "show" ); jQuery("#WB-2, #WB, #BS, #BS-2, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WC$)|(WC%22)|(\+WC")|(\+WC)/im)) {jQuery("#eigenschaften, #unterschiede, #WC-2, #WC, .advice").addClass( "show" ); jQuery("#C-2, #C, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WCS)/im)) {jQuery("#eigenschaften, #unterschiede, #WCS-2, #WCS, .advice").addClass( "show" ); jQuery("#WC-2, #WC, #CS, #CS-2, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WAO$)|(WAO%22)|(\+WAO")|(\+WAO)/im)) {jQuery("#eigenschaften, #unterschiede, #WAO-2, #WAO, .advice").addClass( "show" ); jQuery("#WA-2, #WA, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WAOK$)|(WAOK%22)|(\+WAOK")|(\+WAOK)/im)) {jQuery("#eigenschaften, #unterschiede, #WAOK-2, #WAOK, .advice").addClass( "show" ); jQuery("#WAO-2, #WAO, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(WASY$)|(WASY%22)|(\+WASY")|(\+WASY)/im)) {jQuery("#eigenschaften, #unterschiede, #WASY-2, #WASY, .advice").addClass( "show" ); jQuery("#WA-2, #WA, #WAS-2, #WAS, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(VIA$)|(VIA%22)|(\+VIA")|(\+VIA)/im)) {jQuery("#eigenschaften, #unterschiede, #VIAS-2, #VIAS, .advice").addClass( "show" ); jQuery("#AS, #AS-2, #A, #A-2, #V, #V-2,  #M-2, #M, #R, #R-2").removeClass("show");} else{}
    if(window.location.href.match(/(VIAS$)|(VIAS%22)|(\+VIAS")|(\+VIAS)/im)) {jQuery("#eigenschaften, #unterschiede, #VIAS-2, #VIAS, .advice").addClass( "show" ); jQuery("#AS, #AS-2, #A, #A-2, #V, #V-2, #M-2, #M, #R, #R-2").removeClass("show");} else{}

/*Eriks Suffix*/
    if(window.location.href.match(/(\+R)/im)) {jQuery("#eigenschaften, #unterschiede, #R-2, #R, .advice").addClass( "show" );} else{}
    if(window.location.href.match(/(RST)/im)) {jQuery("#eigenschaften, #unterschiede, #RST-2, #RST, .advice").addClass( "show" ); jQuery("#R-2, #R").removeClass("show");} else{}
    if(window.location.href.match(/(MST)/im)) {jQuery("#eigenschaften, #unterschiede, #MST-2, #MST, .advice").addClass( "show" ); jQuery("#M-2, #M").removeClass("show");} else{}
    if(window.location.href.match(/(GV$)|(GV%22)|(\+GV")|(\+GV)/im)) {jQuery("#eigenschaften, #unterschiede, #GV-2, #GV, .advice").addClass( "show" ); jQuery("#V-2, #V").removeClass("show");} else{}
    if(window.location.href.match(/(GVST)/im)) {jQuery("#eigenschaften, #unterschiede, #GVST-2, #GVST, .advice").addClass( "show" ); jQuery("#GV-2, #GV, #V, #V-2").removeClass("show");} else{}

  	if(window.location.href.match(/(x7.*){2}/im)) {jQuery("#X7, #X7-2").addClass( "show" );} else{}
	    if(window.location.href.match(/(BAX7%22)|(BA\+X7%22)/im)) {jQuery("#X7, .advice").removeClass( "show" ); jQuery(".productRecommendation").removeClass( "show" );} else{}
  	if(window.location.href.match(/(x6.*?)+(x6)/im)) {jQuery("#X6, #X6-2").removeClass( "show" );} else{}
	    if(window.location.href.match(/(BAX6%22)/im)) {jQuery("#X6, #X6-2, .advice").addClass( "show" );} else{}


jQuery("#myModalYoutube").on('hidden.bs.modal', function (e) {
    jQuery("#myModalYoutube iframe").attr("src", jQuery("#myModalYoutube iframe").attr("src"));
});
jQuery('#myModalYoutube').on('hidden.bs.modal', function () {
    var src = jQuery(this).find('iframe').attr('src');
    jQuery(this).find('iframe').attr('src', '');
    jQuery(this).find('iframe').attr('src', src);
});

jQuery(".arealink").on('click', function(e) {
      e.preventDefault();
      jQuery('address').fadeOut('slow');
      jQuery('#' + jQuery(this).attr("id")).fadeIn('slow');
});

});


jQuery("button.lines-button").on('click', function(){
  jQuery(this).toggleClass("close2");
});

// Scroll to
jQuery('.close').on('click', function () {
  jQuery('#myModal', '#myModal2', '#myModal3').hide();
  jQuery('#myModal iframe', '#myModal2 iframe', '#myModal3 iframe').attr("src", jQuery("#myModal iframe", "#myModal2 iframe", "#myModal3 iframe").attr("src"));
});

jQuery( window ).unload(function() {
  jQuery('.product-page ul.products').addClass('intermac').removeAttr('grid');
  jQuery('.product-page ul.products').addClass('intermac').removeClass('grid');
});

// document ready
jQuery(document).ready(function(){
  jQuery('.product-page ul.products').addClass('intermac').removeAttr('grid');
  jQuery('.product-page ul.products').addClass('intermac').removeClass('grid');

  jQuery('#grid').on('click', function() {
  jQuery(this).addClass('active');
  jQuery('#list').removeClass('active');
  jQuery.cookie('gridcookie','grid', { path: '/' });
  jQuery('ul.products').fadeOut(300, function() {
    jQuery(this).addClass('grid').removeClass('list').fadeIn(300);
  });
  return false;
});

jQuery('a[href^="#"]').on('click', function(event) {
    var target = jQuery( jQuery(this).attr('href') );
    if( target.length ) {
        event.preventDefault();
        jQuery('#back-top').animate({
            scrollTop: target.offset().top
        }, 2000);
    }
});

jQuery('.postform').addClass('form-control category');

//end document ready


// clear cf7 error msg on mouseover
jQuery(".wpcf7-form-control-wrap").mouseover(function(){
	$obj = jQuery("span.wpcf7-not-valid-tip",this);
    	   $obj.css('display','none');
	});


jQuery("body").tooltip({ selector: '[data-toggle=tooltip]' });
jQuery('#myModal').appendTo("body");


});
//end document ready


// detect mobile browser
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

if( isMobile.any() ) jQuery('html').addClass('animation-off mobile-browser');

(function() {
    if ("-ms-user-select" in document.documentElement.style && navigator.userAgent.match(/IEMobile\/10\.0/)) {
        var msViewportStyle = document.createElement("style");
        msViewportStyle.appendChild(
            document.createTextNode("@-ms-viewport{width:auto!important}")
        );
        document.getElementsByTagName("head")[0].appendChild(msViewportStyle);
    }
})();

// ie10 class
if (Function('/*@cc_on return document.documentMode===10@*/')()){
    document.documentElement.className+=' ie10';
}